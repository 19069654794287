/* monthlyReport Links do not change color when visited */

a:any-link:visited {
  color: rgb(0, 0, 238);
}

/* ReactDatePicker */

.react-datepicker__year-read-view--down-arrow {
  top: 3px;
}

#formSalesReport tr:nth-child(even),
#salesBudgetYear-Left2 tbody tr:nth-child(even),
#salesBudgetMonth-BottomLeft tbody tr:nth-child(even),
#salesBudgetMonth-BottomRight tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.tr_white_background tr:nth-child(even),
.tr_white_background tr:nth-child(odd) {
  background-color: #fff;
}

.wrap-budget {
  overflow-x: hidden;
  overflow-y: auto;
}

.wrap-budget .table-condensed {
  border-collapse: collapse;
  background-color: '#f9f9f9';
}

.wrap-budget .table-condensed td,
.wrap-budget .table-condensed th {
  border: 1px solid #222d32;
  font-size: 85%;
  text-align: center;
  padding: 5px 0px;
}

.wrap-budget .table-condensed td span {
  padding-right: 5px;
}

.wrap-budget .table-condensed td input {
  width: 110px;
  margin-right: 5px;
}

.wrap-budget .contentLeft .table-condensed td {
  text-align: center;
  height: 32px;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;
}

.twenty_percent_width {
  width: 20% !important;
}

@media only screen and (max-width: 1024px) {
  .wrap-budget .table-condensed {
    height: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .dailyCount {
    margin-left: 0px !important;
  }
  .monthly_shift_earning_wrapper {
    flex-direction: column !important;
  }
}

.wrap-budget .table-condensed td.height_row_contents {
  height: 32px;
}

.wrap-budget .table-content {
  height: calc(100vh - 350px);
  overflow-y: auto;
  position: relative;
  border-top: 5px solid #d2d6de;
}

.width_row_contents {
  width: 15px;
  min-width: 15px;
  max-width: 15px;
}

.width_row_contents_changes {
  width: 5px;
  min-width: 5px;
  max-width: 5px;
}

/* .wrap-budget .width_row_contents {
    width: 11.5%;
} */

.width_row_contents_bottom {
  min-width: 15px;
  max-width: 15px;
  width: 15px;
  height: 20px;
}

.scroll-bottom {
  margin-top: 5px;
  width: 100%;
}

.scroll-bottom .scroll {
  width: 1700px;
  height: 10px;
}

.scroll-bottom-bias-settings .scroll {
  width: 1200px;
  height: 10px;
}

#headRight::-webkit-scrollbar {
  display: none;
}

#contentRight::-webkit-scrollbar {
  display: none;
}

#headRight {
  width: calc(100% - 250px);
  overflow: auto;
}

#headRight table tr th {
  text-align: center;
}

.tooltip {
  position: relative;
}

.tooltip {
  display: block;
}

.tooltip-content {
  position: absolute;
  background-color: #000;
  text-align: center;
  color: #fff;
  transform: translateX(-50%);
  white-space: nowrap;
  border-radius: 4px;
  padding: 6px 10px;
  display: none;
}

.tooltip:hover .tooltip-content {
  display: block;
}

.tooltip-content:before {
  content: '';
  position: absolute;
}

/* POSITION TOP */

.tooltip-content.top {
  bottom: 100%;
  left: 50%;
  margin-bottom: 10px;
}

.tooltip-content.top:before {
  top: 100%;
  left: 50%;
  margin-left: -4px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #000;
}

.icon-tooltip {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.icon-tooltip-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.icon-tooltip .tooltip p {
  margin: 0;
}

.width_row_contents input[type='number'] {
  height: 26px;
  font-size: 12px;
  line-height: 1.5;
  width: 97%;
}

/* #contentRight table tbody tr:nth-child(odd) {
    background: #fff;
}

#contentRight table tbody tr:nth-child(even) {
    background: linear-gradient( 0deg, rgba(242, 242, 242, 0.9), rgba(242, 242, 242, 0.9));
} */

#contentRight table tbody tr.bg-gray {
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.9) 99.99%,
      rgba(255, 255, 255, 0) 100%
    ),
    #0d4796;
}

#formSalesReport table tbody tr:nth-child(odd) {
  background: #fff;
}

#formSalesReport table tbody tr:nth-child(event) {
  background: #fff;
}

.flex_center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.tabLeftBottom table tbody tr:nth-child(odd) {
  background: #fff;
}

.tabLeftBottom table tbody tr:nth-child(even) {
  background: linear-gradient(
    0deg,
    rgba(242, 242, 242, 0.9),
    rgba(242, 242, 242, 0.9)
  );
}

#contentRight input[disabled] {
  cursor: not-allowed;
  background: #fff;
  border: 1px solid #9e9898;
  height: 28px;
}

/* wrap-attend-monthly-list */

.wrap-attend-monthly-list {
  overflow-x: hidden;
  overflow-y: auto;
  color: #333333;
}

.wrap-attend-monthly-list .ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.wrap-attend-monthly-list .table-condensed {
  border-collapse: collapse;
  background-color: '#f9f9f9';
}

.wrap-attend-monthly-list .table-condensed td,
.wrap-attend-monthly-list .table-condensed th {
  font-size: 14px;
  text-align: center;
}

.wrap-attend-monthly-list .contentLeft .table-condensed td {
  text-align: center;
}

.wrap-attend-monthly-list .table-content {
  max-height: 500px;
  overflow-y: auto;
  position: relative;
  overflow-y: scroll;
}

.wrap-attend-monthly-list .border-left-none {
  border-left-style: none !important;
}

.wrap-attend-monthly-list .border-right-none {
  border-right-style: none !important;
}

.wrap-attend-monthly-list .headline-cell-width-70 {
  min-width: 70px;
  max-width: 70px;
}

.wrap-attend-monthly-list .headline-cell-width-80 {
  min-width: 80px;
  max-width: 80px;
}

.wrap-attend-monthly-list .headline-cell-width-95 {
  min-width: 95px;
  max-width: 95px;
}

.wrap-attend-monthly-list .headline-cell-width-110 {
  min-width: 110px;
  max-width: 110px;
}

.wrap-attend-monthly-list .headline-cell-width-120 {
  min-width: 120px;
  max-width: 120px;
}

.wrap-attend-monthly-list th.headline-cell-width-other-item,
.wrap-attend-monthly-list td.headline-cell-width-other-item {
  min-width: 130px;
  max-width: 130px;
}

.wrap-attend-monthly-list .head-line2-height {
  height: 65px;
}

.wrap-attend-monthly-list .headline-cell-width-150 {
  min-width: 150px;
  max-width: 150px;
}

#topRightAreaTime::-webkit-scrollbar,
#bottomRightAreaTime::-webkit-scrollbar {
  display: none;
}

#topRightAreaTime,
#bottomRightAreaTime {
  -ms-overflow-style: none;
  /* IE 11 */
  scrollbar-width: none;
  /* Firefox 64 */
}

#topRightAreaTime table tr th {
  text-align: center;
}

#topRightAreaTime .maxContent {
  width: max-content;
  width: '-webkit-max-content';
  width: '-moz-max-content';
}

.wrap-attend-monthly-list .time-table > tbody > tr > td,
.wrap-attend-monthly-list .time-table > thead > tr > th,
.wrap-attend-monthly-list .time-table > tbody > tr > td {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 1px;
  padding-bottom: 1px;
}

.wrap-attend-monthly-list .margin-bottom-0,
.wrap-attend-monthly-list .margin-bottom-0 {
  margin-bottom: 0px;
}

.wrap-attend-monthly-list .table-condensed td.height_row_contents {
  height: 32px;
}

.wrap-attend-monthly-list .head-line1-height {
  height: 31px;
}

.wrap-attend-monthly-list .head-line3-height {
  height: 99px;
}

.wrap-attend-monthly-list .head-line4-height {
  height: 72px;
}

.wrap-attend-monthly-list .content-scroll-bottom {
  margin-top: 5px;
  width: 100%;
}

.wrap-attend-monthly-list .content-scroll-bottom .scroll {
  height: 10px;
}

.wrap-attend-monthly-list table > thead > tr > th,
.wrap-attend-monthly-list table > thead > tr > th,
.wrap-attend-monthly-list table > tbody > tr > td {
  border: 1px #d3d3d3 solid;
}

.wrap-attend-monthly-list table > thead > tr > th {
  vertical-align: middle !important;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fdfbfb),
    to(#f5f5f5)
  ) !important;
  background: -webkit-linear-gradient(top, #fdfbfb, #f5f5f5) !important;
  background: -moz-linear-gradient(top, #fdfbfb, #f5f5f5) !important;
  background: -o-linear-gradient(top, #fdfbfb, #f5f5f5) !important;
  background: linear-gradient(to bottom, #fdfbfb, #f5f5f5) !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

/*
*/

#formSalesReport {
  padding: 15px;
  font-size: 12px;
}

table > tbody > tr > td {
  vertical-align: middle !important;
}

table > thead > tr > th {
  vertical-align: middle !important;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fdfbfb),
    to(#f5f5f5)
  ) !important;
  background: -webkit-linear-gradient(top, #fdfbfb, #f5f5f5) !important;
  background: -moz-linear-gradient(top, #fdfbfb, #f5f5f5) !important;
  background: -o-linear-gradient(top, #fdfbfb, #f5f5f5) !important;
  background: linear-gradient(to bottom, #fdfbfb, #f5f5f5) !important;
}

.scroll-table {
  overflow: auto;
  white-space: nowrap;
  border: solid 1px #ddd;
}

#formSalesReport table {
  width: 100%;
}

#formSalesReport .overflow-hidden {
  overflow: hidden !important;
  overflow-x: hidden !important;
  overflow-y: hidden;
}

#formSalesReport .clearfix {
  display: block;
  content: '';
  clear: both;
}

#formSalesReport #layoutSale_01 {
  margin-top: -20px;
}

#formSalesReport #layoutSale_02_buttom {
  height: calc(100vh - 300px);
  overflow-y: auto;
  position: relative;
  /* top: -20px; */
  /* border-top: 5px solid #d2d6de; */
}

#formSalesReport .layout_sale_right {
  overflow: auto;
  width: calc(100% - 474px);
}

#formSalesReport .layout_sale_left {
  border-right: 1px solid #d2d6de;
  width: 474px;
  float: left;
}

#formSalesReport .layout_sale_right_content {
}

#formSalesReport #layout_sale_02_buttom_right_set_scroll {
  overflow: auto;
  height: 15px;
  margin-top: -35px;
}

#layout_sale_02_buttom_right_content_set_scroll {
  width: 3518px;
  height: 10px;
}

#formSalesReport tr > td {
  max-width: 100px;
  width: 100px;
  height: 31px;
}

#formSalesReport #searchYearMonth,
#formSalesReport organization_select span {
  height: 31px;
}

#formSalesReport .layout_sale_left tr > td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#formSalesReport .height_row_plus {
  height: 65px;
}

#formSalesReport .table-bordered {
  border-collapse: collapse;
}

#formSalesReport .table-bordered > thead > tr > th,
#formSalesReport .table-bordered > tbody > tr > th,
#formSalesReport .table-bordered > tfoot > tr > th,
#formSalesReport .table-bordered > thead > tr > td,
#formSalesReport .table-bordered > tbody > tr > td,
#formSalesReport .table-bordered > tfoot > tr > td {
  border: 1px solid #222d32;
  border-block: 1px solid #222d32;
}

#formSalesReport td.day_hover:hover {
  background: #00c0ef !important;
}

#formSalesReport .padding_right_10 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 10px !important;
}

#formSalesReport .form-horizontal .control-label {
  padding-top: 0px;
  font-weight: inherit;
}

#formSalesReport .split_sale_repport {
  background: white;
}

#formSalesReport .days-column {
  cursor: pointer;
}

#formSalesReport .saturday {
  color: blue;
}

#formSalesReport .sunday {
  color: red;
}

.colorRed {
  color: red !important;
}

#formSalesReport .stripe-color {
  background: #f6f6ff;
}

/* TemplateTable style */

.templateTable {
  padding: 15px;
  font-size: 12px;
}

.templateTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

.templateTable.newDesignTable tr:nth-child(even) td {
  background-color: #f2f2f2;
}
.templateTable.newDesignTable tr:nth-child(odd) td {
  background-color: #fff;
}

.templateTable.newDesignTable.EllipsisHeader tr td.bgWhite {
  background-color: #fff;
}

.templateTable table {
  width: 100%;
  height: 100%;
}

.templateTable .clearfix {
  display: block;
  content: '';
  clear: both;
}

.templateTable .tablePrint {
  display: none;
}

.templateTable .tableShow {
  display: block;
}

.templateTable .topRow {
  display: flex;
  /* overflow-y: auto; */
}

.templateTable .bottomRow {
  height: calc(100vh - 300px);
  overflow-y: auto;
  position: relative;
  /* top: -20px; */
  /* border-top: 5px solid #d2d6de; */
}

.templateTable .col_right {
  overflow: auto;
  width: calc(100% - 350px);
}

.templateTable .smallCol.col_right {
  width: calc(100% - 198px);
}

/* .templateTable .topRow .smallCol.col_right{
        width: calc(100% - 209px);
} */

.templateTable .col_left {
  border-right: 5px solid #d2d6de;
  width: 345px;
  position: relative;
  float: left;
}

.templateTable .smallCol.col_left {
  width: 187px;
}

.templateTable .topRow .col_left {
  border-bottom: 5px solid #d2d6de;
}

.templateTable .col_right_content {
  width: 3500px;
}

.templateTable .col_right {
  overflow: auto;
  width: calc(100% - 350px);
}

.templateTable .smallCol.col_right {
  width: calc(100% - 198px);
}

.templateTable .col_right_content {
  width: 3500px;
}

.templateTable .topRow .col_right_content {
  border-bottom: 5px solid #d2d6de;
}

.templateTable .topRow .col_right_content.Col_right-MarginPrint {
  margin-right: 16.3px;
}

.headerContentPrint {
  display: none;
}

.templateTable tr > td,
.templateTable tr > th {
  max-width: 100px;
  width: 100px;
  min-width: 100px;
  height: 31px;
}

.templateTable #searchYearMonth,
.templateTable organization_select span {
  height: 31px;
}

.templateTable .col_left tr > td,
.templateTable .col_left tr > th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.templateTable .height_row_plus {
  height: 65px;
}

.templateTable .table-bordered {
  border-collapse: collapse;
}

.templateTable .table-bordered > thead > tr > th,
.templateTable .table-bordered > tbody > tr > th,
.templateTable .table-bordered > tfoot > tr > th,
.templateTable .table-bordered > thead > tr > td,
.templateTable .table-bordered > tbody > tr > td,
.templateTable .table-bordered > tfoot > tr > td {
  border: 1px solid #222d32;
  /* -webkit-box-shadow:0px 0px 1px 1px #222d32; */
  /* -moz-box-shadow:0px 0px 1px 1px #222d32; */
  /* box-shadow: 0px 0px 1px 1px #222d32; */
}

.templateTable td.day_hover:hover {
  background: #00c0ef !important;
}

.templateTable .padding_right_10 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 10px !important;
}

.templateTable .form-horizontal .control-label {
  padding-top: 0px;
  font-weight: inherit;
}

.templateTable .split_sale_repport {
  background: white;
}

.templateTable .days-column {
  cursor: pointer;
}

.templateTable .dayColPrint {
  display: none;
}

.templateTable .saturday {
  color: blue;
}

.templateTable .sunday {
  color: red;
}

.templateTable .stripe-color {
  background: #f6f6ff;
}

.templateTable .row_scroll {
  position: relative;
}

.templateTable .footerRow {
  margin-right: 16px;
}

.templateTable .scrollAction {
  overflow: auto;
  height: 15px;
  margin-top: -15px;
  float: right;
}

.templateTable .scrollActionContent {
  width: 3518px;
  height: 10px;
}

/* Table new fomart */
.templateIcon g {
  fill: #0d4796;
}
.templateIcon button {
  display: flex;
  justify-content: center;
}

.templateNewTable tr {
  font-size: 14px;
  padding: 5px 10px;
  color: #333;
  border: 1px solid #f4f4f4;
  box-sizing: border-box;
}

.templateNewTable tr.bodyRow:nth-child(even) {
  background: #f5f5f5;
}
.templateTable .unScroll::-webkit-scrollbar {
  display: none;
}

/* .templateTable.newDesginTable .table-bordered thead tr th,
.templateTable.newDesginTable .table-bordered tbody tr th,
.templateTable.newDesginTable .table-bordered tfoot tr th,
.templateTable.newDesginTable .table-bordered thead tr td,
.templateTable.newDesginTable .table-bordered tbody tr td,
.templateTable.newDesginTable .table-bordered tfoot tr td
{
    border: 1px solid #222d32;
} */
.templateTable.newDesignTable {
  padding: 0px;
  position: relative;
  overflow: auto;
  height: calc(100vh - 300px);
  z-index: 0;
}
.templateTable.newDesignTable .stickyStyle {
  position: sticky;
}
.templateTable.newDesignTable .text-ellipsis {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.templateTable.newDesignTable .stickyStyle span {
  position: relative;
  z-index: 10;
}
.templateTable.newDesignTable .stickyStyle::before {
  content: '';
  width: 100%;
  /* height: auto; */
  position: absolute;
  top: -1px;
  right: 0;
  bottom: -1px;
  display: block;
  background: transparent;
  left: 0;
  border: 1px solid black;
  border-left: 0.5px;
  /* border-bottom:0.5px; */
}
.templateTable.newDesignTable .fristColSticky:after {
  content: '';
  width: 1px;
  position: absolute;
  top: -1px;
  bottom: -1px;
  display: block;
  background: black;
  left: -1px;
  border-left: 0.5px;
}
.templateTable.newDesignTable table > tfoot > tr > th {
  vertical-align: middle !important;
  background: linear-gradient(to bottom, #fdfbfb, #f5f5f5) !important;
}
/*  end template table */

.templateNewTable tr.headerColor th {
  background-color: #f9cb9c;
  font-size: 14px;
  padding: 5px 10px;
  color: #333;
  border: 1px solid #f4f4f4;
  box-sizing: border-box;
}

.templateNewTable tr.headerColor:nth-child(even) th {
  background-color: #fff2cc !important;
}

.templateNewTable table {
  width: 100%;
}

.templateNewTable .wapperTable {
  position: relative;
  height: calc(100vh - 300px);
  overflow-y: auto;
}

.templateNewTable table th {
  position: sticky;
  top: 0;
}

.templateTable.useBordered {
  padding: 0;
  margin: 15px;
  position: relative;
}

.templateTable.useBordered table {
  border-collapse: collapse !important;
}

.templateTable.useBordered thead tr th {
  background: #f9cb9c !important;
  font-size: 14px;
  padding: 5px 10px;
  color: #333;
  box-sizing: border-box;
}

.templateTable.useBordered thead tr:nth-child(even) th {
  background: #fff2cc !important;
}

.templateTable.useBordered tbody tr:nth-child(even) {
  background-color: #f5f5f5 !important;
}

.templateTable.useBordered tbody tr:nth-child(even) td:nth-child(1) {
  background-color: #f5f5f5 !important;
}

.templateTable.useBordered tbody tr:nth-child(odd) td:nth-child(1) {
  background-color: #fff !important;
}

.templateTable.useBordered thead tr th,
.templateTable.useBordered tbody tr th,
.templateTable.useBordered tfoot tr th,
.templateTable.useBordered thead tr td,
.templateTable.useBordered tbody tr td,
.templateTable.useBordered tfoot tr td {
  border: 1px solid #222d32 !important;
  padding: 0 5px;
  vertical-align: middle;
  text-align: right;
}

.templateTable.useBordered .useSticky tr th {
  text-align: center;
  font-weight: bold;
  z-index: 50;
  top: 0;
}

.templateTable.useBordered .useSticky tr th:nth-child(1) {
  min-width: 230px;
  left: 0;
  z-index: 99;
}

.templateTable.useBordered tr td:nth-child(1) {
  text-align: left;
  position: sticky;
  left: 0;
  z-index: 50;
}

.templateTable.useBordered .useSticky tr th::before {
  content: '';
  width: 100%;
  /* height: auto; */
  position: absolute;
  top: -1px;
  right: 0;
  bottom: -1px;
  display: block;
  background: transparent;
  left: 0;
  border: 1px solid black;
  border-left: 0.5px;
  /* border-bottom:0.5px; */
}

.templateTable.useBordered .useSticky tr th:nth-child(1)::after {
  content: '';
  width: 1px;
  position: absolute;
  top: -1px;
  bottom: -1px;
  display: block;
  background: black;
  left: -1px;
  border-left: 0.5px;
}

.templateTable.useBordered tr td:nth-child(1)::before {
  content: '';
  width: 100%;
  /* height: auto; */
  position: absolute;
  top: -1px;
  right: 0;
  bottom: -1px;
  display: block;
  background: transparent;
  left: 0;
  border: 1px solid black;
  border-left: 0.5px;
  /* border-bottom:0.5px; */
}

.templateTable.useBordered tr td:nth-child(1)::after {
  content: '';
  width: 1px;
  position: absolute;
  top: -1px;
  bottom: -1px;
  display: block;
  background: black;
  left: -1px;
  border-left: 0.5px;
}

@media only screen and (max-width: 890.97px) {
  .templateTable .footerRow {
    margin-right: 0;
  }
  .templateTable .topRow .col_right {
    border-bottom: 5px solid #d2d6de;
  }
  .templateTable .topRow .col_right .col_right_content {
    border-bottom: 0px;
  }
}

@page {
  size: A4;
}

@media print {
  .usePrintTableA4 {
    /* width: initial;   */
    box-shadow: initial;
    background: initial;
    margin: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    height: 100vh;
    max-height: 100vh;
    /* position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; */
    /* overflow: hidden;   */
  }
  .usePrintTableA4 .MainContentPrint {
    overflow: hidden;
  }
  .usePrintTableA4 .headerContentPrint {
    display: block;
    /* margin-top: 50px; */
    padding: 0 15px;
  }
  .usePrintTableA4 .tablePrint {
    display: block;
  }
  .usePrintTableA4 .noStylePrint {
    box-shadow: initial;
    background: initial;
    margin: 0;
    padding: 0;
  }
  .usePrintTableA4 .printA4 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .usePrintTableA4 aside,
  .usePrintTableA4 .hidePrint,
  .usePrintTableA4 .groupToolSearch,
  .usePrintTableA4 .dayColView,
  .usePrintTableA4 .templateTable .tableShow {
    display: none;
  }
  .usePrintTableA4 .css-14pdpu0-FormContents {
    box-shadow: initial;
  }
  .usePrintTableA4 .templateTable {
    box-shadow: initial;
    background: initial;
    /* overflow: hidden; */
  }
  .usePrintTableA4 .templateTable tr > td,
  .usePrintTableA4 .templateTable tr > th {
    text-align: left;
    height: auto !important;
  }
  .usePrintTableA4 .templateTable .col_rightTd a {
    text-decoration: none;
    color: initial;
  }
}

/*  end template table */

textarea::placeholder {
  opacity: 1;
}

/* bias_settings */

.bias-settings__top,
.bias-settings__bottom {
  display: flex;
}

.bias-settings__top .layout_sale_left {
  display: flex;
}

.bias-settings__bottom .layout_sale_left {
  width: 303px;
  margin-top: 0px;
  border-top: 5px solid #d3d6de;
  display: flex;
}

.bias-settings__top .layout_sale_left::after {
  content: '';
  border-right: 5px solid rgb(210, 214, 222);
  margin-left: -3px;
}

.bias-settings__bottom .layout_sale_left::after {
  content: '';
  border-right: 5px solid rgb(210, 214, 222);
}

.bias-settings__bottom .layout_sale_right {
  border-top: 5px solid rgb(210, 214, 222);
}

.bias-settings__bottom .layout_sale_right::-webkit-scrollbar {
  display: none;
}

.bias-settings__top table,
.bias-settings__bottom table {
  border-collapse: collapse;
  border-spacing: 0px;
  border: 1px solid #222d32;
  height: 100%;
}

.bias-settings__top .layout_sale_left table {
  height: 100%;
}

.bias-settings__top tr td {
  padding: 8px 0px;
  border: 1px solid #222d32;
  font-size: 85%;
  width: 75px;
}

.bias-settings__bottom .layout_sale_left tr td {
  text-align: center;
  border: 1px solid #222d32;
  font-size: 85%;
  padding: 8px 0px;
}

.bias-settings__top .layout_sale_right {
  max-width: 1200px;
  overflow: auto;
}

.bias-settings__top .layout_sale_right::-webkit-scrollbar {
  display: none;
}

.bias-settings__top .layout_sale_right table {
  width: 1200px;
  overflow: auto;
}

.sales_plan_daily-settings__bottom {
  max-height: 500px;
  border-top: 5px solid #d2d6de;
  overflow: hidden;
}

.sales_plan_daily-settings__bottom
  #daily_layout_sale_right_2::-webkit-scrollbar {
  display: none;
}

.sales_plan_daily-settings__bottom .layout_sale_right_content1 tr td {
  position: relative;
}

.sales_plan_daily-settings__bottom .layout_sale_right_content1 tr td span {
  width: 96px;
  position: absolute;
  right: 7px;
  top: 7px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.sales_plan_daily-settings__bottom table {
  border-collapse: collapse;
  width: 100%;
}

.sales_plan_daily-settings__bottom table::-webkit-scrollbar {
  display: none;
}

.sales_plan_daily-settings__bottom table tr td {
  border: 1px solid #222d32;
  padding: 3px;
  height: 30px;
}

.sales_plan_daily-settings__bottom table tr td p {
  margin: 0;
}

.sales_plan_daily-settings__bottom table tr td:last-child span {
  justify-content: flex-end;
  padding-right: 5px;
  font-size: 14px;
}

.sales_plan_daily-settings__bottom table tr td span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.bias-settings__bottom .layout_sale_right {
  border-top: 5px solid rgb(210, 214, 222);
  max-width: 1200px;
  overflow: auto;
}

.bias-settings__bottom .layout_sale_right table {
  display: table;
  width: 1200px;
  overflow: auto;
}

.bias-settings__bottom .layout_sale_right tr td {
  border: 1px solid #222d32;
  font-size: 85%;
  padding: 8px;
  text-align: right;
  position: relative;
  height: 20px;
}

.bias-settings__bottom .layout_sale_right tr td span {
  position: absolute;
  top: 7px;
  right: 7px;
}

.bias-settings__bottom input {
  height: 25px;
  margin-right: 3px;
}

.sales_plan_daily-settings__top {
  display: flex;
}

.sales_plan_daily-settings__top table {
  border-collapse: collapse;
}

.sales_plan_daily-settings__top .layout_sale_left {
  border-right: 5px solid rgb(210, 214, 222);
  height: '100%';
}

.sales_plan_daily-settings__top .layout_sale_right table thead tr td {
  padding: 2px;
}

.sales_plan_daily-settings__top .layout_sale_right table thead tr:nth-child(1) {
  height: 49px;
}

.sales_plan_daily-settings__top .layout_sale_right {
  overflow: auto;
}

.sales_plan_daily-settings__top .layout_sale_right tr th {
  padding: 6px;
  background: #fff !important;
}

.sales_plan_daily-settings__top .layout_sale_right tr th select {
  padding: 5px;
  height: 30px;
  width: 80px;
}

.sales_plan_daily-settings__top .red-day,
[data-is='sales_budget_monthly'] .red-day {
  color: red;
}

.sales_plan_daily-settings__top .saturday {
  color: blue;
}

.scroll-bottom-daily-settings .scroll {
  height: 10px;
  width: 3250px;
}

.sales_plan_daily-settings__top table tr td,
.sales_plan_daily-settings__top table tr th {
  border: 1px solid #222d32;
  font-size: 85%;
}

/* start .wrap-attend-stamp-list */

.wrap-attend-stamp-list {
  overflow-x: hidden;
  overflow-y: auto;
  color: #333333;
}

.wrap-attend-stamp-list .table-condensed {
  border-collapse: collapse;
  background-color: '#f9f9f9';
}

.wrap-attend-stamp-list .table-condensed td,
.wrap-attend-stamp-list .table-condensed th {
  font-size: 14px;
  text-align: center;
}

.wrap-attend-stamp-list .contentLeft .table-condensed td {
  text-align: center;
}

.wrap-attend-stamp-list .table-content {
  max-height: 500px;
  overflow-y: auto;
  position: relative;
  overflow-y: scroll;
}

.wrap-attend-stamp-list .panel {
  font-size: 14px;
  border-color: #ddd;
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  border: 1px solid #ddd;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  color: #333;
}

.wrap-attend-stamp-list .panel-default > .panel-heading {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
}

.wrap-attend-stamp-list .bg-primary {
  color: #fff;
  background-color: #337ab7;
}

.wrap-attend-stamp-list .panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.wrap-attend-stamp-list .panel-body {
  padding: 15px;
}

.wrap-attend-stamp-list .head-line4-height {
  height: 72px;
}

.wrap-attend-stamp-list .content-scroll-bottom .scroll {
  height: 10px;
}

.wrap-attend-stamp-list .table-condensed,
.wrap-attend-stamp-list .table-bordered {
  border: 1px solid #f4f4f4;
  border-spacing: 0;
  border-collapse: separate;
}

.wrap-attend-stamp-list .table-bordered th,
.wrap-attend-stamp-list .table-bordered td {
  text-align: center;
}

.wrap-attend-stamp-list .table-bordered > thead > tr > th,
.wrap-attend-stamp-list .table-bordered > tbody > tr > th,
.wrap-attend-stamp-list .table-bordered > tfoot > tr > th,
.wrap-attend-stamp-list .table-bordered > thead > tr > td,
.wrap-attend-stamp-list .table-bordered > tbody > tr > td,
.wrap-attend-stamp-list .table-bordered > tfoot > tr > td {
  border: 1px solid #f4f4f4;
}

.wrap-attend-stamp-list .table-responsive {
  overflow-x: auto;
}

.wrap-attend-stamp-list .table-condensed > tbody > tr > td,
.wrap-attend-stamp-list .table-condensed > tbody > tr > th,
.wrap-attend-stamp-list .table-condensed > tfoot > tr > td,
.wrap-attend-stamp-list .table-condensed > tfoot > tr > th,
.wrap-attend-stamp-list .table-condensed > thead > tr > td,
.wrap-attend-stamp-list .table-condensed > thead > tr > th {
  padding: 5px;
  border: 1px solid #f4f4f4;
  box-sizing: border-box;
}

.wrap-attend-stamp-list .border-right-none {
  border-right: none;
}

.wrap-attend-stamp-list .border-left-none {
  border-left: none;
}

.wrap-attend-stamp-list .head-line1-height {
  height: 60px;
}

.wrap-attend-stamp-list .button-type-1 {
  background-color: #00a65a;
  border-color: #008d4c;
  color: #fff;
  outline: none;
  position: relative;
  font-size: 12px;
}

.wrap-attend-stamp-list .button-type-2 {
  background-color: #00c0ef;
  border-color: #00acd6;
  color: #fff;
  outline: none;
  position: relative;
  font-size: 12px;
}

/* .wrap-attend-stamp-list .button-type-2:after,
.wrap-attend-stamp-list .button-type-1:after {
content: "\25BE";
display: inline-block;
position: absolute;
right: 7px;
font-size: 7px;
top: 10px;
} */

/* end .wrap-attend-stamp-list */

/* start .react-contextmenu */

.react-contextmenu {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  color: #373a3c;
  font-size: 16px;
  margin: 2px 0 0;
  min-width: 160px;
  outline: none;
  opacity: 0;
  padding: 5px 0;
  /* pointer-events: none; */
  text-align: left;
  transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  /* pointer-events: auto; */
  z-index: 9999;
}

.react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: #373a3c;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  padding: 3px 20px;
  text-align: inherit;
  white-space: nowrap;
  font-size: 14px;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  color: #fff;
  background-color: #20a0ff;
  border-color: #20a0ff;
  text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
  color: #878a8c;
}

.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0;
}

.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu
  > .react-contextmenu-item:after {
  content: '\25B6';
  display: inline-block;
  position: absolute;
  right: 7px;
  font-size: 7px;
  top: 10px;
}

/* end .react-contextmenu */

.wrap-attend-stamp-list .button-type-1 {
  background-color: #00a65a;
  border-color: #008d4c;
  color: #fff;
  outline: none;
  position: relative;
}

.wrap-attend-stamp-list .button-type-2 {
  background-color: #00c0ef;
  border-color: #00acd6;
  color: #fff;
  outline: none;
  position: relative;
}

/* .wrap-attend-stamp-list .button-type-2:after,
.wrap-attend-stamp-list .button-type-1:after {
content: "\25BE";
display: inline-block;
position: absolute;
right: 7px;
font-size: 7px;
top: 10px;
} */

/* end .wrap-attend-stamp-list */

/* start .react-contextmenu */

.react-contextmenu {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  color: #373a3c;
  font-size: 16px;
  margin: 2px 0 0;
  min-width: 160px;
  outline: none;
  opacity: 0;
  padding: 5px 0;
  /* pointer-events: none; */
  text-align: left;
  transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  /* pointer-events: auto; */
  z-index: 9999;
}

.react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: #373a3c;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  padding: 3px 20px;
  text-align: inherit;
  white-space: nowrap;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  color: #fff;
  background-color: #20a0ff;
  border-color: #20a0ff;
  text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
  color: #878a8c;
}

.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0;
}

.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu
  > .react-contextmenu-item:after {
  content: '\25B6';
  display: inline-block;
  position: absolute;
  right: 7px;
  font-size: 7px;
  top: 10px;
}

/* end .react-contextmenu */

/*
*/

#formSalesReport {
  padding: 15px;
  font-size: 12px;
}

table > tbody > tr > td {
  vertical-align: middle !important;
}

table > thead > tr > th {
  vertical-align: middle !important;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fdfbfb),
    to(#f5f5f5)
  ) !important;
  background: -webkit-linear-gradient(top, #fdfbfb, #f5f5f5) !important;
  background: -moz-linear-gradient(top, #fdfbfb, #f5f5f5) !important;
  background: -o-linear-gradient(top, #fdfbfb, #f5f5f5) !important;
  background: linear-gradient(to bottom, #fdfbfb, #f5f5f5) !important;
}

.scroll-table {
  overflow: auto;
  white-space: nowrap;
  border: solid 1px #ddd;
}

table {
  margin: 0;
  border: none;
}

#formSalesReport table {
  width: 100%;
}

#formSalesReport .overflow-hidden {
  overflow: hidden !important;
  overflow-x: hidden !important;
  overflow-y: hidden;
}

#formSalesReport .clearfix {
  display: block;
  content: '';
  clear: both;
}

#formSalesReport #layoutSale_01 {
  margin-top: -20px;
}

#formSalesReport #layoutSale_02_buttom {
  height: calc(100vh - 300px);
  overflow-y: auto;
  position: relative;
  top: 0px;
  border-top: 5px solid #d2d6de;
}

#formSalesReport .layout_sale_right {
  overflow: auto;
  width: calc(100% - 474px);
}

#formSalesReport .layout_sale_right table tbody tr td span {
  margin-right: 5px;
}

#formSalesReport .layout_sale_left {
  border-right: 5px solid #d2d6de;
  width: 469px;
  float: left;
}

#formSalesReport .layout_sale_left .table1 {
  width: 230px;
}

#formSalesReport .layout_sale_left .table2 {
  width: 355px;
}

.table2 tr td:first-child {
  border-left: 0 !important;
}

#formSalesReport .layout_sale_left tr:nth-child(odd),
#formSalesReport .layout_sale_left tr:nth-child(even) {
  background: #fff;
}

#formSalesReport .layout_sale_left tr:nth-child(odd),
#formSalesReport.salesMonthlyReport .layout_sale_left tr:nth-child(even),
#formSalesReport.salesMonthlyReport .layout_sale_right tr:nth-child(even) {
  background: #fff;
}

#SalesDailyReport .layout_sale_left tr:nth-child(odd),
#SalesDailyReport .layout_sale_left tr:nth-child(even) {
  background: #fff;
}

#SalesDailyReport .layout_sale_left table tr td {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;
}

.f_rate_table1 tr:nth-child(2n + 3) {
  background: #f2f2f2 !important;
}

.f_rate_table2 tr:nth-child(2n + 2) {
  background: #f2f2f2 !important;
}

.light_gray_background {
  background: #f2f2f2 !important;
}

#formSalesReport .layout_sale_right_content {
}

#formSalesReport #layout_sale_02_buttom_right_set_scroll {
  overflow: auto;
  height: 15px;
  margin-top: -17px;
}

#layout_sale_02_buttom_right_content_set_scroll {
  width: 3518px;
  height: 10px;
}

#formSalesReport tr > td {
  max-width: 100px;
  width: 100px;
  height: 31px;
}

#formSalesReport #searchYearMonth,
#formSalesReport organization_select span {
  height: 31px;
}

#formSalesReport .layout_sale_left tr > td:nth-child(2) {
  /* white-space: nowrap; */
  /* max-width:195px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* padding-left:5px; */
}

#formSalesReport .height_row_plus {
  height: 65px;
}

#formSalesReport .table-bordered {
  border-collapse: collapse;
}

#formSalesReport .table-bordered > thead > tr > th,
#formSalesReport .table-bordered > tbody > tr > th,
#formSalesReport .table-bordered > tfoot > tr > th,
#formSalesReport .table-bordered > thead > tr > td,
#formSalesReport .table-bordered > tbody > tr > td,
#formSalesReport .table-bordered > tfoot > tr > td {
  border: 1px solid #222d32;
}

#formSalesReport td.day_hover:hover {
  background: #00c0ef !important;
}

#formSalesReport .padding_right_10 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 10px !important;
}

#formSalesReport .form-horizontal .control-label {
  padding-top: 0px;
  font-weight: inherit;
}

#formSalesReport .split_sale_repport {
  background: white !important;
}

#SalesDailyReport .split_sale_repport {
  background: white !important;
}

#formSalesReport .days-column {
  cursor: pointer;
}

#formSalesReport .saturday {
  color: blue;
}

#formSalesReport .sunday {
  color: red;
}

#formSalesReport .stripe-color {
  background: #f6f6ff;
}

textarea::placeholder {
  opacity: 1;
}

.text-right {
  text-align: right;
}

.overflow-hidden {
  overflow: hidden !important;
  overflow-x: hidden !important;
  overflow-y: hidden;
}

.clearfix {
  display: block;
  content: '';
  clear: both;
}

#SalesDailyReport {
  padding: 15px;
  font-size: 12px;
}

#SalesDailyReport #layoutSale_01 {
  /*margin-top: -20px;*/
}

#SalesDailyReport #layoutSale_02_buttom {
  height: calc(100vh - 410px);
  overflow-y: auto;
  position: relative;
  border-top: 5px solid #d2d6de;
}

#SalesDailyReport .layout_sale_right {
  overflow: auto;
  width: calc(100% - 350px);
}

#SalesDailyReport .layout_sale_left {
  border-right: 5px solid #d2d6de;
  width: 340px;
  float: left;
}

#SalesDailyReport .layout_sale_right_content {
  width: 850px;
}

#SalesDailyReport #layout_sale_02_buttom_right_set_scroll {
  overflow: auto;
  height: 15px;
  margin-top: -17px;
  float: right;
}

#layout_sale_02_buttom_right_content_set_scroll_daily {
  width: 818px;
  height: 10px;
}

#SalesDailyReport tr > td {
  max-width: 100px;
  width: 100px;
  white-space: initial;
  height: 27px;
}

#SalesDailyReport tr > td > span {
  margin-right: 5px;
}

.DiscountInformationTd .DiscountInformationItem {
  width: 100%;
  white-space: initial;
  height: 30px;
}

#SalesDailyReport .height_row_plus {
  height: 56px !important;
}

#SalesDailyReport .table-bordered {
  border-collapse: collapse;
  width: 100%;
}

#SalesDailyReport .table-bordered > thead > tr > th,
#SalesDailyReport .table-bordered > tbody > tr > th,
#SalesDailyReport .table-bordered > tfoot > tr > th,
#SalesDailyReport .table-bordered > thead > tr > td,
#SalesDailyReport .table-bordered > tbody > tr > td,
#SalesDailyReport .table-bordered > tfoot > tr > td {
  border: 1px solid #222d32;
  height: 27px;
  text-overflow: ellipsis;
  overflow: hidden;
}

#SalesDailyReport td.day_hover:hover {
  background: #00c0ef !important;
}

#SalesDailyReport .form-horizontal .control-label {
  padding-top: 0px;
  font-weight: inherit;
}

#SalesDailyReport .edit_yellow {
  background-color: yellow;
}

#SalesDailyReport .edited_red {
  background-color: red;
}

textarea::placeholder {
  opacity: 1;
}

#SalesDailyReport textarea {
  width: 100%;
  margin-top: 15px;
  padding: 10px;
}

.table-report-month {
  margin-top: 15px;
}

.table-report-month tfoot tr th {
  text-align: right;
}

.table-report-month tfoot tr th:first-child {
  text-align: center;
}

.table-report-month tr th,
.table-report-month tr td {
  border: 1px solid #222d32;
  font-weight: normal;
  font-size: 14px;
  padding: 7px;
  white-space: nowrap;
}

.table-report-month tr th {
  text-align: center;
}

.table-report-month tr td {
  text-align: right;
}

.table-report-month tr td:nth-child(1),
.table-report-month tr td:nth-child(2) {
  text-align: center;
}

.table-report-month tr td.text-right {
  text-align: right;
}

.table-time_day_separate_sales tbody tr td:first-child {
  width: 150px;
  white-space: nowrap;
}

.table-time_day_separate_sales tr th {
  white-space: nowrap;
}

.table-time_day_separate_sales tbody tr td {
  height: 20px;
  width: 50px;
  white-space: nowrap;
}

/* Table fixed */

.wrap_time_day_separate_sales .contentHead .tblLeft {
  width: 187px;
  position: relative;
  border: 1px solid #222d32;
  background-color: #f9f8f8;
}

.wrap_time_day_separate_sales .contentHead .tblLeft table tr th {
  white-space: nowrap;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
}

.wrap_time_day_separate_sales .contentHead .tblRight table tr th {
  white-space: nowrap;
  border: 1px solid #222d32;
  padding: 7px 5px;
  font-size: 14px;
  text-overflow: ellipsis;
}

.discount_sales_management .contentHead .tblRight table tr th {
  white-space: nowrap;
  border-right: 1px solid #222d32;
  border-left: 1px solid #222d32;
  border-top: 0px solid #222d32;
  border-bottom: 0px solid #222d32;
  padding: 7px 5px;
  font-size: 14px;
  text-overflow: ellipsis;
}

.daily_monthly_discount_sales_management .contentHead .tblRight table tr th {
  white-space: nowrap;
  border-right: 0px solid #222d32;
  border-left: 1px solid #222d32;
  border-top: 0px solid #222d32;
  border-bottom: 0px solid #222d32;
  padding: 7px 5px;
  font-size: 14px;
  text-overflow: ellipsis;
}

.wrap_time_day_separate_sales .contentHead .tblRight table tr th:nth-child(1) {
  border-left: 0px;
}

.wrap_time_day_separate_sales .contentHead .tblRight {
  width: calc(100% - 190px);
}

.wrap_time_day_separate_sales .contentHead .tblRight table {
  border-collapse: collapse;
  width: 100%;
  display: table;
  border: 1px solid;
  border-left: 0px;
}

.wrap_time_day_separate_sales .contentTable {
  overflow: auto;
}

.wrap_time_day_separate_sales .contentTable .tblLeft {
  border-top: 0px;
  overflow: auto;
  width: 189px;
  overflow-y: hidden;
}

.wrap_time_day_separate_sales .contentTable .tblLeft::-webkit-scrollbar {
  display: none;
  overflow: hidden;
}

.wrap_time_day_separate_sales .contentTable .tblLeft tr:first-child td {
  border-top: 0px;
}

.wrap_time_day_separate_sales .contentTable .tblLeft table {
  border-collapse: collapse;
  width: 100%;
}

.wrap_time_day_separate_sales .contentTable .tblLeft table tr td {
  white-space: nowrap;
  font-weight: normal;
  border: 1px solid #222d32;
  padding: 7px 20px;
  font-size: 14px;
  height: 20px;
}

.wrap_time_day_separate_sales .contentHead .tblRight {
  overflow: auto;
}

.wrap_time_day_separate_sales .contentHead .tblRight::-webkit-scrollbar {
  display: none;
}

.wrap_time_day_separate_sales .contentTable .tblRight {
  overflow: auto;
  width: calc(100% - 190px);
  overflow-y: hidden;
}

.wrap_time_day_separate_sales .contentTable .tblRight table {
  border-collapse: collapse;
  width: 100%;
  display: table;
  border: 1px solid;
  border-left: 0;
  border-top: 0;
}

.wrap_time_day_separate_sales .contentTable .tblRight table tr td {
  white-space: nowrap;
  font-weight: normal;
  border: 1px solid #222d32;
  padding: 7px 5px;
  font-size: 14px;
  position: relative;
  border-left: 0;
  border-top: 0;
  height: 20px;
}

.wrap_time_separate_products_sales .contentHead .tblRight table tr th {
  padding: 7px 5px !important;
}

.wrap_time_separate_products_sales .contentTable .tblRight table tr td span {
  top: 7px;
  width: 100px !important;
  display: block;
}

.wrap_time_day_separate_sales .contentTable .tblRight table tr {
  height: 32px;
}

.wrap_time_day_separate_sales .contentTable .tblRight table tr td span {
  /* width: 120px; */
  display: block;
  min-width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 120px;
}

.wrap_time_day_separate_sales .contentTable .tblRight::-webkit-scrollbar {
  display: none;
}

.wrap_time_day_separate_sales .scroller {
  display: flex;
}

.wrap_time_day_separate_sales .scrollLeft {
  width: 189px;
}

.wrap_time_day_separate_sales .scrollRight {
  width: calc(100% - 189px);
  float: right;
  height: 20px;
  overflow-y: hidden;
}

.wrap_time_day_separate_sales .scrollRight .scrollAction {
  width: 1612px;
  height: 10px;
}

/* wrap_time_separate_sales */

.wrap_time_separate_sales .contentHead .tblLeft {
  width: 448px;
  position: relative;
  background-color: #f9f8f8;
}

.wrap_time_separate_sales .contentHead .tblLeft {
  display: flex;
  align-items: center;
  border: 1px solid;
}

.wrap_time_separate_sales .contentHead .tblLeft table {
  width: 448px;
  border-collapse: collapse;
}

.wrap_time_separate_sales .contentHead .tblLeft table tr th {
  height: 55px;
  font-size: 14px;
  padding: 7px;
}

@media screen and (max-width: 1024px) {
  .wrap_time_separate_sales .contentHead .tblLeft table tr th {
    height: 59px;
  }
}

.wrap_time_separate_sales .contentHead .tblLeft table tr th span {
  white-space: nowrap;
  width: 100%;
  height: 67px;
  font-size: 14px;
}

.wrap_time_separate_sales .contentHead .tblRight table tr th {
  white-space: nowrap;
  border: 1px solid #222d32;
  padding: 7px 5px;
  font-size: 14px;
}

.wrap_time_separate_sales .contentHead .tblRight table tr th:nth-child(1) {
  border-left: 0px;
}

.wrap_time_separate_sales .contentHead .tblRight {
  width: calc(100% - 400px);
}

.wrap_time_separate_sales .contentHead .tblRight table {
  border-collapse: collapse;
}

.wrap_time_separate_sales .contentTable {
  overflow: auto;
}

.wrap_time_separate_sales .contentTable .tblLeft {
  border-top: 0px;
  overflow: auto;
  width: 189px;
  overflow-y: hidden;
}

.wrap_time_separate_sales .contentTable .tblLeft::-webkit-scrollbar {
  display: none;
  overflow: hidden;
}

.wrap_time_separate_sales .contentTable .tblLeft tr:first-child td {
  border-top: 0px;
}

.wrap_time_separate_sales .contentTable .tblLeft tr:nth-child(2) td {
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrap_time_separate_sales tr td span {
  display: block;
  font-size: 14px;
}

.wrap_time_separate_sales .contentTable .tblLeft table {
  border-collapse: collapse;
}

.wrap_time_separate_sales .contentTable .tblLeft table tr td {
  white-space: nowrap;
  font-weight: normal;
  border: 1px solid #222d32;
  padding: 7px;
  font-size: 14px;
}

.wrap_time_separate_sales .contentTable .tblLeft table tr td:nth-child(2) {
  width: 200px;
}

.wrap_time_separate_sales .contentTable .tblLeft table tr td:nth-child(2) span {
  font-size: 12px;
}

.wrap_time_separate_sales .contentTable .tblLeft table tr td span {
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
}

.wrap_time_separate_sales .contentHead .tblRight {
  overflow: auto;
}

.wrap_time_separate_sales .contentHead .tblRight::-webkit-scrollbar {
  display: none;
}

.wrap_time_separate_sales .contentTable .tblRight {
  overflow: auto;
  width: calc(100% - 189px);
  overflow-y: hidden;
}

.wrap_time_separate_sales .contentTable .tblRight table {
  border-collapse: collapse;
  width: 100%;
}

.wrap_time_separate_sales .contentTable .tblRight table tr td {
  white-space: nowrap;
  font-weight: normal;
  border: 1px solid #222d32;
  padding: 7px 5px;
  font-size: 14px;
  position: relative;
  border-left: 0;
  height: 20px;
  border-top: 0;
}

.wrap_time_separate_sales .contentTable .tblRight table tr {
  height: 32px;
}

.wrap_time_separate_sales .contentTable .tblRight table tr td span {
  width: 100px;
}

.wrap_time_separate_sales .contentTable .tblRight::-webkit-scrollbar {
  display: none;
}

.wrap_time_separate_sales .scroller {
  display: flex;
}

.wrap_time_separate_sales .scrollLeft {
  width: 450px;
}

.wrap_time_separate_sales .scrollRight {
  width: calc(100% - 450px);
  float: right;
  height: 24px;
  overflow: auto;
}

.wrap_time_separate_sales .scrollRight .scrollAction {
  width: 1612px;
  height: 10px;
}

#tblNotFound {
  width: 100%;
  border: 1px solid;
  border-top: 0px;
  text-align: center;
}

#tblNotFound::-webkit-scrollbar {
  display: none;
}

#daily_layout_sale_left table tr td:last-child {
  width: 85px;
}

#daily_layout_sale_left table tr td:nth-child(2) {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;
}

.empty_column {
  min-width: 25px;
  width: 5% !important;
  border: 0px solid black !important;
  border-left: 1px solid black !important;
}

.sales_plan_daily-settings__top .column_change,
#daily_layout_sale_left .column_change {
  width: 15%;
}

.wrap-budget #contentRight tr td {
  text-align: right;
  height: 32px;
}

.lightOrange {
  background-color: #fce5cd;
}

.lightBlue {
  background-color: #cfe2f3;
}

.lightYellow {
  background-color: #fff2cc;
}

.lightPurple {
  background-color: #c9daf8;
}

@media screen and (max-width: 1024px) {
  .wrap-budget #contentRight tr td {
    text-align: right;
    height: 33px;
  }
}

.wrap_time_separate_products_sales .tbl_fixcolumn tr td:nth-child(1) {
  position: relative;
}

.wrap_time_separate_products_sales .tbl_fixcolumn tr td:nth-child(1) span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 104px;
}

/* wrap_sales_management_table */

.wrap_sales_management_table tr th span,
.wrap_sales_management_table table tr td span {
  width: 100%;
  display: block;
  position: relative;
  white-space: nowrap;
  font-size: 14px;
}

.wrap_sales_management_table .contentHead .tblLeft {
  width: 448px;
}

.wrap_sales_management_table tr th {
  position: relative !important;
  transform: translate(0) !important;
  left: 0 !important;
  top: 0 !important;
}

.wrap_sales_management_table .contentHead .tblLeft table {
  border-collapse: collapse;
  width: 100%;
}

.wrap_sales_management_table .contentHead .tblRight {
  width: calc(100% - 450px);
}

.wrap_sales_management_table table .contentHead table tr th span {
  position: absolute;
  top: 0;
  left: 0;
}

.wrap_sales_management_table .contentHead .tblLeft tr th:nth-child(1) {
  border-right: 1px solid;
  height: 67px;
  width: 159px;
}

.hide_scrollbar_onscroll::-webkit-scrollbar {
  /* width: 0px; */
  height: 0px;
  background: transparent;
  /* make scrollbar transparent */
}

@media screen and (max-width: 1024px) {
  .wrap_sales_management_table .contentHead .tblLeft tr th:nth-child(1) {
    height: 71px;
  }
}

.wrap_sales_management_table .contentHead .tblLeft tr th:nth-child(2) {
  width: 282px;
}

.wrap_sales_management_table .contentTable .tblLeft {
  width: 450px;
  float: left;
}

.wrap_sales_management_table .contentTable .tblLeft table {
  width: 100%;
  text-align: center;
}

.wrap_sales_management_table .contentTable .tblRight {
  width: calc(100% - 450px);
}

.wrap_sales_management_table .contentTable .tblLeft table tr td span {
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
}

.wrap_sales_management_table .contentTable .tblLeft table tr td:nth-child(1) {
  width: 147px;
}

.wrap_sales_management_table .contentTable .tblLeft table tr td:nth-child(2) {
  width: 282px;
}

.wrap_sales_management_table
  .contentTable
  .tblLeft
  table
  tr
  td:nth-child(1)
  span {
  width: 147px;
}

@media screen and (max-width: 1440px) {
  .wrap_sales_management_table
    .contentTable
    .tblLeft
    table
    tr
    td:nth-child(1)
    span {
    width: 148px;
  }
}

@media screen and (max-width: 1024px) {
  .wrap_sales_management_table
    .contentTable
    .tblLeft
    table
    tr
    td:nth-child(1)
    span {
    width: 147.5px;
  }
}

.wrap_sales_management_table
  .contentTable
  .tblLeft
  table
  tr
  td:nth-child(2)
  span {
  width: 272px;
  font-size: 12px;
}

@media screen and (max-width: 1440px) {
  .wrap_sales_management_table
    .contentTable
    .tblLeft
    table
    tr
    td:nth-child(2)
    span {
    width: 271px;
  }
}

.wrap_sales_management_table .contentHead .tblRight table tr th {
  padding: 7px;
}

.wrap_sales_management_table .contentTable .tblRight table tr td {
  padding: 7px;
}

.wrap_sales_management_table .contentHead .tblRight table tr th span {
  width: 100px;
  display: block;
}

.wrap_sales_management_table .contentTable .tblRight table {
  width: 100%;
}

.wrap_sales_management_table .contentTable .tblRight table tr td span {
  /* width: 120px; */
  display: block;
  position: relative;
  text-align: right;
  top: 0;
  left: 0;
  min-width: 120px;
  margin: 0 auto;
}

.wrap_sales_management_table
  .contentTable
  .tblRight
  table
  tr
  td.text-center
  span {
  text-align: center;
}

.wrap_sales_management_table
  .contentTable
  .tblRight
  table
  tr
  td
  span.text-center {
  text-align: center;
}

.wrap_sales_management_table table {
  font-size: 14px;
}

.wrap_sales_management_table .contentTable .tblLeft table tr td {
  padding: 7px 7px;
}

.wrap_sales_management_table .contentTable {
  max-height: 600px;
}

/* wrap_monthly_day_separate_sales_management */

.wrap_monthly_day_separate_sales_management .contentHead .tblRight table tr th {
  padding: 7px 5px;
}

.wrap_monthly_day_separate_sales_management .contentHead .tblRight tr th span {
  width: 50px;
  display: flex;
  justify-content: center;
}

.wrap_monthly_day_separate_sales_management
  .contentHead
  .tblRight
  tr
  th
  .fixsize {
  font-size: 12px;
}

.wrap_monthly_day_separate_sales_management
  .contentHead
  .tblRight
  tr
  th
  .fixsize {
  font-size: 12px;
}

.wrap_monthly_day_separate_sales_management
  .contentTable
  .tblRight
  table
  tr
  td {
  padding: 7px 5px;
}

.wrap_monthly_day_separate_sales_management
  .contentTable
  .tblRight
  table
  tr
  td
  span {
  left: 4px;
}

.thead-bottom {
  bottom: -10px;
  top: auto !important;
  border-top: 1px solid #222d32;
  width: 99%;
  background: transparent !important;
  padding-top: 8px;
  font-size: 14px;
}

.wrap_time_day_separate_sales .contentHead .tblLeft table tr th {
  background: transparent !important;
}

.wrap_time_day_separate_sales .contentFooter {
  display: flex;
}

.wrap_time_day_separate_sales .contentFooter .tblLeft {
  width: 189px;
}

.wrap_time_day_separate_sales .contentFooter .tblLeft table {
  border-collapse: collapse;
  width: 100%;
  font-size: 14px;
}

.wrap_time_day_separate_sales .contentFooter .tblLeft table tr th {
  border: 1px solid;
  height: 37px;
}

.wrap_time_day_separate_sales .contentFooter .tblRight {
  width: calc(100% - 194px);
  overflow: auto;
}

.wrap_time_day_separate_sales .contentFooter .tblRight::-webkit-scrollbar {
  display: none;
}

.wrap_time_day_separate_sales .contentFooter .tblRight tr th {
  padding: 7px 5px;
  height: 25px;
  border: 1px solid;
  position: relative;
  width: 120px;
}

.wrap_time_day_separate_sales .contentFooter .tblRight table {
  border-collapse: collapse;
  display: table;
  border: 1px solid;
  border-left: 0px;
  font-size: 14px;
  width: 100%;
}

.wrap_time_day_separate_sales .contentFooter .tblRight tr th span {
  /* width: 120px; */
  display: block;
  min-width: 120px;
}

.wrap_time_day_separate_sales .contentFooter .tblRight tr th:nth-child(1) {
  border-left: 0px;
}

.wrap_sales_management_table_abc table {
  border-collapse: collapse;
  width: 100%;
}

.wrap_sales_management_table_abc .contentTable .tblRight table {
  border-collapse: collapse;
  display: table;
  border: 1px solid;
  border-left: 0px;
  font-size: 14px;
  border-top: 0;
  border-bottom: 0;
}

.wrap_sales_management_table_abc tr td {
  padding: 7px 17px;
  border: 1px solid;
  position: relative;
  width: 100px;
  height: 25px;
  font-size: 14px;
}

.wrap_sales_management_table_abc tr th {
  border: 1px solid;
  height: 25px;
  font-size: 14px;
  padding: 7px 17px;
}

.wrap_sales_management_table_abc tr th span,
.wrap_sales_management_table_abc tr td span {
  width: 100%;
  display: block;
  position: relative;
  white-space: nowrap;
  font-size: 14px;
}

.wrap_sales_management_table_abc .tblRight table thead tr th:nth-child(1) {
  border-left: 0px;
}

.wrap_sales_management_table_abc tr td.not_found_item {
  border-top: 0px;
  border-bottom: 0px;
}

.wrap_sales_management_table_abc .contentFooter {
  display: flex;
}

.wrap_sales_management_table_abc .contentFooter .tblLeft {
  width: 650px;
  position: relative;
}

.wrap_sales_management_table_abc .contentTable {
  display: flex;
  font-size: 14px;
  overflow: hidden;
}

.wrap_sales_management_table_abc .contentTable .tblLeft {
  width: 650px;
  position: relative;
}

.wrap_sales_management_table_abc .contentTable .tblLeft tr td {
  border-top: 0px;
}

.wrap_sales_management_table_abc .contentTable .tblLeft tr td span {
  position: relative;
  transform: translate(-50%, 0%);
  left: 50%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.wrap_sales_management_table_abc .contentHead .tblLeft tr th:nth-child(1) {
  width: 98px;
}

.wrap_sales_management_table_abc .contentHead .tblLeft tr th:nth-child(2) {
  width: 98px;
}

.wrap_sales_management_table_abc .contentHead .tblLeft tr th:nth-child(3) {
  width: 194px;
}

.wrap_sales_management_table_abc .contentHead .tblLeft tr th:nth-child(4) {
  width: 259px;
}

.wrap_sales_management_table_abc .contentTable .tblLeft tr td:nth-child(1) {
  width: 98px;
}

.wrap_sales_management_table_abc .contentTable .tblLeft tr td:nth-child(2) {
  width: 98px;
}

.wrap_sales_management_table_abc
  .contentTable
  .tblLeft
  tr
  td:nth-child(1)
  span {
  width: 81px;
}

@media screen and (max-width: 1440px) {
  .wrap_sales_management_table_abc
    .contentTable
    .tblLeft
    tr
    td:nth-child(1)
    span {
    width: 82px;
  }
}

@media screen and (max-width: 1024px) {
  .wrap_sales_management_table_abc
    .contentTable
    .tblLeft
    tr
    td:nth-child(1)
    span {
    width: 81.5px;
  }
}

.wrap_sales_management_table_abc
  .contentTable
  .tblLeft
  tr
  td:nth-child(2)
  span {
  width: 77px;
}

.wrap_sales_management_table_abc .contentTable .tblLeft tr td:nth-child(2) {
  width: 81px;
}

.wrap_sales_management_table_abc .contentTable .tblLeft tr td:nth-child(3) {
  width: 194px;
}

.wrap_sales_management_table_abc
  .contentTable
  .tblLeft
  tr
  td:nth-child(3)
  span {
  width: 157px;
}

.wrap_sales_management_table_abc .contentTable .tblLeft tr td:nth-child(4) {
  width: 259px;
}

.wrap_sales_management_table_abc
  .contentTable
  .tblLeft
  tr
  td:nth-child(4)
  span {
  width: 194px;
  font-size: 12px;
}

@media screen and (max-width: 1440px) {
  .wrap_sales_management_table_abc
    .contentTable
    .tblLeft
    tr
    td:nth-child(4)
    span {
    width: 193px;
    font-size: 12px;
  }
}

.wrap_sales_management_table_abc .contentTable .tblRight tr td {
  border-top: 0px;
}

.wrap_sales_management_table_abc .contentTable .tblRight {
  width: calc(100% - 650px);
  overflow: auto;
}

.wrap_sales_management_table_abc .contentTable .tblRight::-webkit-scrollbar {
  display: none;
}

.wrap_sales_management_table_abc .contentTable .tblRight tr td span {
  width: 126px;
}

.wrap_sales_management_table_abc .contentTable .tblRight tr td span.text-right {
  text-align: right;
}

.wrap_sales_management_table_abc .contentHead .tblRight tr th span {
  width: 126px;
}

.wrap_sales_management_table_abc .contentTable .tblRight tr td:nth-child(1) {
  border-left: 0px;
}

.wrap_sales_management_table_abc .contentTable {
  overflow: hidden;
  font-size: 14px;
}

.wrap_sales_management_table_abc .contentHead {
  display: flex;
}

.wrap_sales_management_table_abc .contentHead .tblLeft {
  width: 650px;
  position: relative;
}

.wrap_sales_management_table_abc .contentHead .tblRight {
  width: calc(100% - 650px);
  overflow: hidden;
}

.wrap_sales_management_table_abc .contentFooter .tblRight {
  width: calc(100% - 650px);
  overflow: auto;
  height: 60px;
}

.wrap_sales_management_table_abc .contentFooter .tblRight tr th:nth-child(1) {
  border-left: 0px;
}

.wrap_sales_management_table_abc table tr td {
  border-top: 0px;
}

.wrap_sales_management_table_abc .contentFooter .tblRight tr th span {
  width: 126px;
}

.wrap_sales_management_table_abc .contentTable tr:last-child td {
  border-bottom: 0px;
}

/* wrap_sales_management_table_cnt_order_abc */

.wrap_sales_management_table_cnt_order_abc .contentTable .tblRight tr td span {
  white-space: nowrap;
}

.wrap_time_separate_sales
  .contentTable
  .tblLeft
  .column-width-fixed
  tr
  td:nth-child(1) {
  height: 20px;
}

.wrap_time_separate_sales
  .contentTable
  .tblLeft
  .column-width-fixed
  tr
  td:nth-child(1)
  span {
  width: 100px;
}

.wrap_time_separate_sales
  .contentTable
  .tblLeft
  .column-width-fixed
  tr
  td:nth-child(2)
  span {
  width: 190px;
}

.wrap_time_separate_sales
  .contentTable
  .tblLeft
  .column-width-fixed
  tr
  td:nth-child(3)
  span {
  width: 80px;
}

.wrap_time_separate_sales
  .contentHead
  .tblLeft
  .column-width-fixed
  tr
  td:nth-child(1) {
  width: 120px;
}

.wrap_time_separate_sales
  .contentHead
  .tblLeft
  .column-width-fixed
  tr
  td:nth-child(2) {
  width: 199px;
}

.wrap_time_separate_sales
  .contentTable
  .tblLeft
  .column-width-fixed
  tr
  td:nth-child(3) {
  width: 86px;
}

.wrap_time_separate_sales
  .contentHead
  .tblLeft
  .column-width-fixed
  tr
  th:nth-child(1) {
  width: 117px;
  border-right: 1px solid;
}

.wrap_time_separate_sales
  .contentHead
  .tblLeft
  .column-width-fixed
  tr
  th:nth-child(2) {
  width: 198px;
  border-right: 1px solid;
}

.wrap_time_separate_sales
  .contentHead
  .tblLeft
  .column-width-fixed
  tr
  th:nth-child(3) {
  width: 85px;
}

.wrap_time_day_separate_sales .contentHead .tblRight table tr th span {
  /* width: 120px; */
  display: block;
  margin: 0 auto;
  min-width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.wrap_time_separate_sales .contentHead .tblRight table tr th span {
  width: 100px;
  display: block;
}

#scrollTableNotFound::-webkit-scrollbar {
  display: none;
}

/* style icon cancle for MultiSelectGroup */

.chip .icon_cancel {
  color: #333333;
}

#selectGroup {
  top: 15px;
}

#selectGroup div:nth-child(1) {
  min-height: 30px;
}

.cardContentDashboard {
  padding: 8px 8px;
}

.cardContentBodyText {
  text-overflow: hidden;
  font-size: 16px !important;
}

.cardContentBodyValue {
  text-overflow: hidden;
  font-size: 22px !important;
}

.cardContentBodyPercent {
  text-overflow: hidden;
  font-size: 16px !important;
}

#paidHolidayGrantList {
  table-layout: fixed;
  min-width: 1348px;
  white-space: nowrap;
}

#paidHolidayGrantList th {
  font-size: 14px;
  color: #333;
  padding: 5px;
}

#paidHolidayGrantList > tbody > tr > td,
#paidHolidayGrantList > thead > tr > td {
  border: 1px solid #f4f4f4;
  box-sizing: border-box;
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 5px;
  color: #333;
}

.requestDashboard table {
  border-collapse: collapse;
}

.requestDashboard table tr td {
  vertical-align: top !important;
}

.requestDashboard > div > div > table > thead > tr,
.requestDashboard > div > div > table > tbody > tr {
  border-bottom: 1px solid #d3d3d3;
}

/* salesBudgetYear */

.p-left {
  padding-left: 5px !important;
}

.p-right {
  padding-right: 5px !important;
}

@media only screen and (max-width: 1024px) {
  .cardContentDashboard {
    padding: 8px 5px;
  }
  .cardContentBodyText,
  .cardContentBodyPercent {
    font-size: 9px !important;
  }
  .cardContentBodyValue {
    font-size: 13px !important;
  }
}

@media only screen and (max-width: 768px) {
  .cardContentBodyText,
  .cardContentBodyPercent {
    font-size: 14px !important;
  }
  .cardContentBodyValue {
    font-size: 18px !important;
  }
}

#salesPageForm thead > tr > th,
#salesPageDeficiencies thead > tr > th,
#salesAuditList thead > tr > th,
#headquarterPointedOut thead > tr > th {
  /* background: rgb(242, 245, 249) !important; */
  background: #f9cb9c !important;
  vertical-align: middle !important;
}

#salesPageForm thead > tr > th.color_merge,
#salesAuditList thead > tr > th.color_merge,
#salesPageForm tfoot > td {
  /* background: '#fff2cc' ; */
  background: rgb(255, 242, 204) !important;
}

#salesPageForm thead > tr.color_merge_two th {
  background: #ffd966 !important;
}

#salesPageForm thead > tr.color_merge_one th {
  background: rgb(255, 242, 204) !important;
}

/*  custom row total */

.custom_total td {
  font-size: 14px;
  padding: 5px 10px;
  color: #333;
  border: 1px solid #f4f4f4;
  box-sizing: border-box;
  background: #f9cb9c !important;
}

#salesPageForm thead > tr > th,
#salesPageForm tbody > tr > td,
#salesPageForm tfoot > tr > td,
#salesPageDeficiencies thead > tr > th,
#salesPageDeficiencies tbody > tr > td,
#salesAuditList thead > tr > th,
#salesAuditList tbody > tr > td,
#headquarterPointedOut thead > tr > th,
#headquarterPointedOut tbody > tr > td,
.operationTable thead > tr > th,
.operationTable tbody > tr > td,
.operationTable tfoot > tr > td {
  font-size: 14px;
  padding: 5px 10px;
  color: #333;
  border: 1px solid #f4f4f4;
  box-sizing: border-box;
}

#salesPageForm tbody > tr > td,
#salesPageForm tfoot > tr > td,
#salesPageDeficiencies tbody > tr > td,
#salesAuditList tbody > tr > td,
#headquarterPointedOut tbody > tr > td {
  text-align: right;
}

#salesPageForm tbody > tr:nth-child(odd),
#salesPageDeficiencies tbody > tr:nth-child(odd),
#salesAuditList tbody > tr:nth-child(odd),
#headquarterPointedOut tbody > tr:nth-child(odd),
.operationTable tbody > tr:nth-child(odd) {
  background: #f5f5f5;
}

#salesPageForm tbody > tr:nth-child(even),
#salesPageDeficiencies tbody > tr:nth-child(even),
#salesAuditList tbody > tr:nth-child(even),
#headquarterPointedOut
  tbody
  > tr:nth-child(even)
  .operationTable
  tbody
  > tr:nth-child(even) {
  background: #fff;
}

#salesPageForm tbody > tr.activeClickFrames,
#salesPageForm tbody > tr > td.activeClickFrames,
#salesPageDeficiencies tbody > tr.activeClickFrames,
#salesPageDeficiencies tbody > tr > td.activeClickFrames,
#salesAuditList tbody > tr.activeClickFrames,
#salesAuditList tbody > tr > td.activeClickFrames {
  background: #b0e368;
}

#salesPageDeficiencies thead > tr > th:first-child,
#salesPageDeficiencies tbody > tr > td:first-child,
#salesPageDeficiencies thead > tr > th:nth-child(3),
#salesPageDeficiencies tbody > tr > td:nth-child(3),
#salesPageDeficiencies thead > tr > th:nth-child(4),
#salesPageDeficiencies tbody > tr > td:nth-child(4),
#salesPageDeficiencies thead > tr > th:nth-child(7),
#salesPageDeficiencies tbody > tr > td:nth-child(7) {
  width: 5%;
}

#salesPageDeficiencies thead > tr > th:nth-child(2),
#salesPageDeficiencies tbody > tr > td:nth-child(2) {
  width: 25%;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#salesPageDeficiencies thead > tr > th:nth-child(5),
#salesPageDeficiencies tbody > tr > td:nth-child(5) {
  width: 32%;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#salesPageDeficiencies thead > tr > th:nth-child(6),
#salesPageDeficiencies tbody > tr > td:nth-child(6) {
  width: 33%;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#salesAuditList thead > tr > th:nth-child(6),
#salesAuditList tbody > tr > td:nth-child(6) {
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#headquarterPointedOut thead > tr > th:nth-child(2),
#headquarterPointedOut tbody > tr > td:nth-child(2),
#salesPageForm .storeStyle {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.print:hover svg path,
.print:hover span,
.timeRecorder:hover svg path,
.timeRecorder:hover span {
  fill: #333333;
  color: #333333;
}

.dropdown-menu .dropdown-item {
  display: block;
  padding: 8px;
  background-color: #fff;
  color: #000;
  text-decoration: none;
  left: 2px;
}

.dropdown-menu .dropdown-item:hover {
  background-color: #f2f5f9;
}

#dropdown-basic {
  background: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
}

.dropdown-menu.show {
  width: 67px;
  border-radius: 3px;
  border: 1px solid #dcdcdc;
}

#headquarterPointedOut thead > tr > th:nth-child(5),
#headquarterPointedOut tbody > tr > td:nth-child(5),
#headquarterPointedOut thead > tr > th:nth-child(6),
#headquarterPointedOut tbody > tr > td:nth-child(6),
#headquarterPointedOut thead > tr > th:nth-child(7),
#headquarterPointedOut tbody > tr > td:nth-child(7) {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#headquarterPointedOut thead th,
#salesPageForm thead th,
#salesPageDeficiencies thead th,
#salesAuditList thead th {
  position: sticky;
  top: 0;
}

thead > tr.subThead > th {
  top: 32px !important;
}

thead > tr.subThead2 > th {
  top: 64px !important;
}

.textLeft {
  text-align: left !important;
}

.textCenter {
  text-align: center !important;
}

.textRight {
  text-align: right !important;
}

/* DashBoard request table */

.DBoardRequestTable table tr td {
  vertical-align: top !important;
  white-space: nowrap;
}

.DBoardRequestTable a:any-link {
  text-decoration: unset;
}

/* BalanceStatementStore */

.templateTable.newDesignTable {
  padding: 0;
  position: relative;
  overflow: auto;
  height: calc(100vh - 300px);
  z-index: 2;
}

.templateTable.newDesignTable .stickyStyle {
  position: sticky;
}

.templateTable.newDesignTable .stickyStyle span {
  position: relative;
  z-index: 10;
}

.templateTable.newDesignTable .stickyStyle::before {
  content: '';
  width: 100%;
  /* height: auto; */
  position: absolute;
  top: -1px;
  right: 0;
  bottom: -1px;
  display: block;
  background: transparent;
  left: 0;
  border: 1px solid black;
  border-left: 0.5px;
  /* border-bottom:0.5px; */
}

.templateTable.newDesignTable .fristColSticky:after {
  content: '';
  width: 1px;
  position: absolute;
  top: -1px;
  bottom: -1px;
  display: block;
  background: black;
  left: -1px;
  border-left: 0.5px;
}

.titleColumn tr td:nth-child(1) {
  position: sticky;
  background: white;
}

.EllipsisHeader .titleColumn tr > th span {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 5px;
}

.fontWeightTitle {
  font-weight: bold;
  padding-left: 5px;
}

.backroundTitle {
  background: #f5f5f5 !important;
}

table.incomeExpenditureTable {
  border-spacing: 2;
  border-collapse: collapse;
  width: 100%;
}

table.incomeExpenditureTable thead {
  background-color: #f2f2f2;
}

table.incomeExpenditureTable thead > tr > th.stickyHeader {
  position: sticky;
  top: 0;
  border-top: none;
  border-bottom: none;
  box-shadow: inset 0 1px 0 #000000, inset 0 -1px 0 #000000;
}

table.incomeExpenditureTable thead > tr > th.analyticsTotal {
  width: 170px;
}

table.incomeExpenditureTable tbody > tr > td.analytics {
  width: 85px;
}

table.incomeExpenditureTable thead > tr > th,
table.incomeExpenditureTable tbody > tr > td {
  padding: 5px;
  font-size: 12px;
  border: 1px solid black;
}

table.incomeExpenditureTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

table.incomeExpenditureTable tr:first-child > td {
  border-top: none;
}

table.incomeExpenditureTableSecond {
  width: max-content;
}

table.incomeExpenditureTableSecond thead > tr > th:first-child,
table.incomeExpenditureTableSecond tbody > tr > td:first-child {
  border-left: none;
}

table.incomeExpenditureTableSecond thead > tr > th {
  width: 211px;
}

table.incomeExpenditureTableSecond tbody > tr > td {
  width: 100px;
  text-align: right;
}

/* dailyShift dayCount */

.dailyCount tr,
.dailyCount th,
.dailyCount td {
  border: 1px solid #dcdcdc;
  font-size: 14px;
  word-spacing: 5px;
}

.dailyCount tr th {
  font-weight: bold;
  color: #000;
  height: 25px;
}

.dailyCount tr td {
  width: 125px;
  text-align: right;
  height: 40px;
  padding: 0 6px;
}

/* Start Monthly Shift Earning table */

.monthly_shift_earning_wrapper table {
  border: 1px solid #d3d3d3;
  border-collapse: collapse;
  margin-bottom: 25px;
}

.monthly_shift_earning_wrapper table tr,
.monthly_shift_earning_wrapper table tr th,
.monthly_shift_earning_wrapper table tr td {
  height: 32px;
  padding: 0 6px;
  border: 1px solid #d3d3d3;
}

.monthly_shift_earning_wrapper table tr th {
  font-size: 14px;
  font-weight: 700;
}

.monthly_shift_earning_left {
  width: 350px;
  margin-right: 25px;
}

.monthly_shift_earning_left td:first-child {
  text-align: center;
}

.monthly_shift_earning_left td:not(:first-child) {
  text-align: right;
}

.monthly_shift_earning_right {
  width: 100%;
}

.monthly_shift_earning_right td:first-child {
  text-align: center;
}

.monthly_shift_earning_right td:not(:first-child) {
  text-align: right;
}

/* End Monthly Shift Earning table */

.IncomeAndExpenditureSchedule .year-picker {
  z-index: 3;
}

.whiteBackground {
  background-color: #fff;
}

/* Bank Deposit Import Page fixes specific width for primary button */
.bankCustomizedButton button {
  width: 96px;
}
table.percentRegistration {
  font-size: 14px;
}
table.percentRegistration > tbody > tr > td {
  border: 1px solid #f4f4f4;
}
.tableNumberIndicationAnalysis table > tbody > div > tr > td:first-child {
  overflow: hidden;
  text-overflow: ellipsis;
}
.operationTable thead > tr > th,
.operationTable tfoot > tr > td {
  background: rgb(214, 214, 214) !important;
  vertical-align: middle !important;
}
table.operationTable tbody {
  display: block;
  max-height: 256px;
  overflow: auto;
}
table.operationTable thead,
table.operationTable tbody tr,
table.operationTable tfoot tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
table.operationTable tbody tr td:first-child,
table#operationTableReportNew tr td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

table.operationTable {
  border-spacing: 0;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  width: calc(100% - 15px);
  overflow: visible;
}

table.operationTable thead th,
table.operationTable tbody tr td,
table.operationTable tfoot tr td {
  border-spacing: 0;
  border-right: 1px solid black;
  border-top: 1px solid black;
}
table#operationTableReportNew tbody {
  max-height: unset;
  overflow: unset;
}
table#operationTableReportNew thead > tr > th:first-child,
table#operationTableReportNew tbody > tr > td:first-child,
table#operationTableReportNew thead > tr > th:nth-child(3),
table#operationTableReportNew tbody > tr > td:nth-child(3) {
  width: 50px;
}
table#operationTableReportNew thead > tr > th:nth-child(2),
table#operationTableReportNew tbody > tr > td:nth-child(2) {
  width: 60px;
}
table#operationTableReportNew thead > tr > th:nth-child(4),
table#operationTableReportNew tbody > tr > td:nth-child(4) {
  width: 70px;
}
table#operationTableReportNew thead > tr > th:nth-child(5),
table#operationTableReportNew tbody > tr > td:nth-child(5) {
  width: 140px;
}
table#operationTableReportNew thead > tr > th:nth-child(6),
table#operationTableReportNew tbody > tr > td:nth-child(6) {
  width: 460px;
}
table#operationTableReportNew thead > tr > th:nth-child(7),
table#operationTableReportNew tbody > tr > td:nth-child(7) {
  width: 120px;
}
table#operationTableReportNew thead > tr > th:nth-child(8),
table#operationTableReportNew tbody > tr > td:nth-child(8) {
  width: 150px;
}
.tickyTotalRight {
  left: 0;
  bottom: 0;
  position: sticky;
  position: -webkit-sticky;
}

/* shogun/periodSales */

.periodSalesTable {
  padding: 15px;
  font-size: 12px;
}

.periodSalesTable .table-bordered > thead > tr > th,
.periodSalesTable .table-bordered > tbody > tr > th,
.periodSalesTable .table-bordered > thead > tr > td,
.periodSalesTable .table-bordered > tbody > tr > td {
  border: 1px solid #222d32;
}

.periodSalesTable.newDesignTable {
  padding: 0px;
  position: relative;
  overflow: auto;
  height: calc(100vh - 300px);
  z-index: 0;
}

.periodSalesTable.newDesignTable .stickyStyle {
  position: sticky;
}

.periodSalesTable.newDesignTable .stickyStyle span {
  position: relative;
  z-index: 10;
}
.periodSalesTable.newDesignTable .stickyStyle::before {
  content: '';
  width: 100%;
  position: absolute;
  top: -1px;
  right: 0;
  bottom: -1px;
  display: block;
  background: transparent;
  left: 0;
  border: 1px solid black;
  border-left: 0.5px;
}
.periodSalesTable.newDesignTable .fristColSticky:after {
  content: '';
  width: 1px;
  position: absolute;
  top: -1px;
  bottom: -1px;
  display: block;
  background: black;
  left: -1px;
  border-left: 0.5px;
}

.templateNewTable table th {
  position: sticky;
  top: 0;
}

.periodSalesTable.newDesignTable.nobackground table tr td {
  background-color: #fff;
}
.periodSalesTable.newDesignTable.nobackground table > tbody > tr:last-child td {
  background-color: #dddddd;
}
.periodSalesTable.newDesignTable.nobackground table > tr td:last-child {
  border-right: none;
}

.periodSalesTable .table-bordered {
  border-collapse: collapse;
}
.periodSalesTable.newDesignTable table tr th {
  background-color: #dddddd;
}
.periodSalesTable.newDesignTable.nobackground tr td.bgGray {
  background: #dddddd;
}
.periodSalesTable.newDesignTable.nobackground .bgGray {
  background: #dddddd;
}
.periodSalesTable.newDesignTable.nobackground table > thead > tr > th {
  background: #dddddd !important;
}
.pr-10 {
  padding-right: 10px;
}
.periodSalesTable table thead > tr > th,
.periodSalesTable table tbody > tr > td,
.periodSalesTable table tfoot > tr > td {
  padding: 5px 10px;
}
.periodSalesTable table thead > tr > th:first-child,
.periodSalesTable table tbody > tr > td:first-child {
  width: 90px;
  min-width: 90px;
  text-align: left;
}
.periodSalesTable table thead > tr > th:nth-child(2),
.periodSalesTable table tbody > tr > td:nth-child(2),
.periodSalesTable table thead > tr > th:nth-child(3),
.periodSalesTable table tbody > tr > td:nth-child(3) {
  width: 42px;
  min-width: 42px;
}

.periodSalesTable table thead > tr > th:nth-child(4),
.periodSalesTable table tbody > tr > td:nth-child(4) {
  width: 65px;
  min-width: 65px;
}
.periodSalesTable table thead > tr > th:nth-child(5),
.periodSalesTable table tbody > tr > td:nth-child(5) {
  width: 60px;
  min-width: 60px;
}

.periodSalesTable table thead > tr > th:nth-child(n + 6):nth-child(-n + 11),
.periodSalesTable table tbody > tr > td:nth-child(n + 4):nth-child(-n + 11) {
  width: 68px;
  min-width: 68px;
}
.periodSalesTable table thead > tr > th:nth-child(12),
.periodSalesTable table tbody > tr > td:nth-child(12) {
  width: 60px;
  min-width: 60px;
}

.periodSalesTable table thead > tr > th:last-child,
.periodSalesTable table tbody > tr > td:last-child {
  width: 75px;
  min-width: 75px;
}
/* end shogun PeriodSales */
table#shogunStampHistory .table-bordered > thead > tr > th,
table#shogunStampHistory .table-bordered > tbody > tr > td {
  border: 1px solid #222d32 !important;
}
table#shogunStampHistory thead > tr > th {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}
table#shogunStampHistory thead > tr > th,
table#shogunStampHistory tbody > tr > td {
  font-size: 14px;
  padding: 5px 10px;
  color: #333;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
}
table#shogunStampHistory thead > tr > th:last-child,
table#shogunStampHistory tbody > tr > td:last-child {
  border-right: 1px solid black;
}
table#shogunStampHistory thead > tr > th {
  background: rgb(214, 214, 214) !important;
}

/* shogun/operationalReportList */

.operationalReportListTable .table-bordered > tbody > tr > th,
.operationalReportListTable .table-bordered > thead > tr > td,
.operationalReportListTable .table-bordered > tbody > tr > td,
.operationalReportListTable .table-bordered > tfoot > tr > td {
  border: 1px solid #222d32;
}

.operationalReportListTable.newDesignTable {
  padding: 0px;
  position: relative;
  overflow: auto;
  /* height: calc(100vh - 300px); */
  z-index: 0;
}

.operationalReportListTable.newDesignTable .stickyStyle {
  position: sticky;
}
.operationalReportListTable.newDesignTable .stickyStyle span {
  position: relative;
  z-index: 10;
}
.operationalReportListTable.newDesignTable .stickyStyle::before {
  content: '';
  width: 100%;
  position: absolute;
  top: -1px;
  right: 0;
  bottom: -1px;
  display: block;
  background: transparent;
  left: 0;
  border: 1px solid black;
  border-left: 0.5px;
}
.operationalReportListTable.newDesignTable .fristColSticky:after {
  content: '';
  width: 1px;
  position: absolute;
  top: -1px;
  bottom: -1px;
  display: block;
  background: black;
  left: -1px;
  border-left: 0.5px;
}
.templateNewTable table th {
  position: sticky;
  top: 0;
}
.operationalReportListTable.newDesignTable.nobackground table tr td {
  background-color: #fff;
  height: 31px;
}
.operationalReportListTable.newDesignTable.nobackground
  table
  > tr
  td:last-child {
  border-right: none;
}
.operationalReportListTable .table-bordered {
  border-collapse: collapse;
}
.operationalReportListTable.newDesignTable table tr th {
  background: #d6d6d6 !important;
  height: 31px;
}
