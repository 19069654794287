.monthly-shift {
    width: 100%;
    .fixed-left {
        width: 160px;
        float: left;
        overflow-x: hidden;
    }
    .scrollable-right {
        float: left;
        overflow-x: scroll;
        width: 950px;
    }
}

.table__monthly-shift {
    border-spacing: 0!important;
    .cell__itemWrapper {
        .calendar-item:not(:last-of-type) {
            margin-bottom: 5px;
        }
        font-size: 12px;
    }
}

.d-flex {
    display: flex;
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

.freeze-col {
    position: sticky;
    left: 0;
    background-color: white;
    // border-left: none!important;
    box-shadow: 1px 0 0 -1px #dcdcdc, -1px 0 0 -1px #dcdcdc;

    &__header {
        z-index: 4;
    }

    &__row {
        z-index: 2;

        &--expand {
            left: 20px;
        }
    }
}

.freeze-col-2 {
    position: sticky;
    left: 95px;
    z-index: 1;
    background-color: white;
    box-shadow: -1px 0 0 -1px #dcdcdc, 1px 1px 0 0 #dcdcdc;
}

#table-header::-webkit-scrollbar {
    width: 0px;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */
}

.border-l-none {
    border-left: none!important;
}

.border-r-none {
    border-right: none!important;
}

.border-b-none {
    border-bottom: none!important;
}

.border-b-white {
    border-bottom: 1px solid white!important;
}

.border-t-none {
    border-top: none!important;
}
